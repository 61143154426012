import { SchedulePharmacy } from '@core/interfaces/pharmacy.interface';
import { TranslateService } from '@ngx-translate/core';

export function formatSchedule(rawSchedule: string, translateService?: TranslateService): string[] {
  const daysMapping: { [key: string]: string } = {
    mon: 'monday',
    tue: 'tuesday',
    wen: 'wednesday',
    thu: 'thursday',
    fri: 'friday',
    sat: 'saturday',
    sun: 'sunday',
  };

  let schedule: SchedulePharmacy;

  try {
    schedule = JSON.parse(rawSchedule);
  } catch (error) {
    return [''];
  }

  const grouped = Object.entries(schedule).reduce(
    (acc, [day, time]) => {
      const timeKey = time.join('-');

      if (timeKey === '00:00-00:00') {
        acc['day_off'] = acc['day_off'] || [];
        acc['day_off'].push(translateService!.instant(daysMapping[day]));
      } else {
        acc[timeKey] = acc[timeKey] || [];
        acc[timeKey].push(translateService!.instant(daysMapping[day]));
      }
      return acc;
    },
    {} as { [key: string]: string[] },
  );

  return Object.entries(grouped).map(([time, days]) => {
    const dayRange = days.length > 1 ? `${days[0]}-${days[days.length - 1]}` : days[0];

    return time === 'day_off'
      ? `${dayRange}: ${translateService!.instant('day_off')}`
      : `${dayRange}: ${time.replace('-', ' - ')}`;
  });
}
